const state = {
    searchResults: []
}

const getters = {
    searchResults: (state) => state.searchResults
}

const mutations = {
    setSearchResults: (state, searchResults) => { 
        state.searchResults = searchResults 
    }
}

const actions = {
    updateSearchResults({ commit }, searchResults) {
        commit('setSearchResults', searchResults)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}