<template>
	<p class="current-recipe-name">
		<span v-if="!editMode">{{ currentRecipe.name }}</span>
		<input v-else v-model="recipeEditedName" />
	</p>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters(["currentRecipe", "editMode", "currentRecipeEdited"]),
		recipeEditedName: {
			get() {
				return this.$store.state.recipe.currentRecipeEdited.name;
			},
			set(value) {
				this.$store.commit("setCurrentRecipeEditedName", value);
			},
		},
	},
};
</script>

<style>
.current-recipe-name,
.current-recipe-name > input {
	font-size: 25px;
	font-weight: 600;
	margin-bottom: 10px;
	width: 100%;
}
</style>
