<template>
	<div>
		<div id="navbar" v-if="isAuthenticated">
			<div v-if="!editMode" class="navbar-tab" @click="redirect('/cookbook')">
				<font-awesome-icon class="navbar-tab-icon" icon="book-open" />
				<span>Mes recettes</span>
			</div>
			<div
				v-if="!isRecipeView && !isSharedRecipeView"
				class="navbar-tab"
				@click="redirect('/menu')"
			>
				<font-awesome-icon class="navbar-tab-icon" icon="file-alt" />
				<span>Nouvelle recette</span>
			</div>
			<div
				v-if="isRecipeView && !editMode"
				class="navbar-tab"
				@click="enterEditMode"
			>
				<font-awesome-icon class="navbar-tab-icon" icon="edit" />
				<span>Éditer la recette</span>
			</div>
			<div
				v-if="isRecipeView && editMode"
				class="navbar-tab"
				@click="quitEditMode"
			>
				<font-awesome-icon class="navbar-tab-icon" icon="times" />
				<span>Arrêter l'édition</span>
			</div>
			<div
				v-if="isRecipeView && !editMode"
				class="navbar-tab"
				@click="deleteRecipe"
			>
				<font-awesome-icon class="navbar-tab-icon" icon="trash" />
				<span>Supprimer</span>
			</div>
			<div
				v-if="isRecipeView && !isNewRecipeView && !editMode"
				class="navbar-tab"
				@click="showSocialModal"
			>
				<font-awesome-icon class="navbar-tab-icon" icon="share" />
				<span>Partager</span>
			</div>
			<div
				v-if="isNewRecipeView || editMode || isSharedRecipeView"
				class="navbar-tab"
				@click="saveRecipe"
			>
				<font-awesome-icon class="navbar-tab-icon" icon="save" />
				<span>Sauvegarder</span>
			</div>
		</div>
		<div id="navbar-anonymous" v-else>
			<div id="navbar-login-btn" @click="authenticate">
				<span>Je me connecte</span>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyANfZadN0CsNHoRXmygbkKQJrCz_PyUHhg",
	authDomain: "pingo-3dddd.firebaseapp.com",
	databaseURL: "https://pingo-3dddd.firebaseio.com",
	projectId: "pingo-3dddd",
	storageBucket: "pingo-3dddd.appspot.com",
	messagingSenderId: "1072875444140",
	appId: "1:1072875444140:web:1f6ebcc82584fbf39b0c09",
	measurementId: "G-YPG47C08LC",
};

firebase.initializeApp(firebaseConfig);

firebase.getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
			unsubscribe();
			resolve(user);
		}, reject);
	});
};

export default {
	methods: {
		...mapActions([
			"saveNewRecipeDB",
			"saveRecipeDB",
			"insertNewRecipeStore",
			"fetchUserRecipes",
			"deleteExistingRecipeDB",
			"removeExistingRecipeStore",
			"updateExistingRecipeStore",
			"updateCurrentRecipe",
			"updateCurrentRecipeEdited",
			"enterEditMode",
			"quitEditMode",
			"updateUser",
			"updateIsSavingRecipe",
		]),
		authenticate() {
			const provider = new firebase.auth.GoogleAuthProvider();
			firebase
				.auth()
				.signInWithPopup(provider)
				.then(async () => {
					const currentUser = await firebase.getCurrentUser();
					this.updateUser(currentUser);
					this.fetchUserRecipes();
					if (!this.isSharedRecipeView) this.$router.push("/cookbook");
				})
				.catch(function (error) {
					console.log(error.code, error.message, error.email);
				});
		},
		redirect(to) {
			if (this.$route.path != to) {
				this.$router.push(to);
			}
		},
		async saveRecipe() {
			this.updateIsSavingRecipe(true);
			if (this.isNewRecipeView) {
				if (this.editMode) this.quitEditMode(false);
				const savedRecipe = await this.saveRecipeDB({
					recipe: this.currentRecipe,
				});
				this.insertNewRecipeStore(savedRecipe);
				this.$router.push("/cookbook");
			} else if (this.isSharedRecipeView) {
				let sharedRecipe = JSON.parse(JSON.stringify(this.currentRecipe));
				sharedRecipe.id = null;
				sharedRecipe.user_id = null;
				sharedRecipe.created_at = null;
				sharedRecipe.image = null;
				console.log(sharedRecipe);
				const savedRecipe = await this.saveRecipeDB({
					recipe: sharedRecipe,
				});
				this.insertNewRecipeStore(savedRecipe);
				this.$router.push("/cookbook");
			} else if (this.editMode) {
				this.quitEditMode();
				const savedRecipe = await this.saveRecipeDB({
					recipe: this.currentRecipeEdited,
				});
				this.updateCurrentRecipe(savedRecipe);
				this.updateExistingRecipeStore(savedRecipe);

				this.$router.push("/cookbook");
			}
			this.updateIsSavingRecipe(false);
		},
		async deleteRecipe() {
			this.updateIsSavingRecipe(true);
			if (!this.isNewRecipeView) {
				await this.deleteExistingRecipeDB(this.currentRecipe);
				this.removeExistingRecipeStore(this.currentRecipe);
			}
			this.$router.push("/cookbook");
			this.updateIsSavingRecipe(false);
		},
		cancelNewRecipe() {
			this.$router.push("/cookbook");
		},
		showSocialModal() {
			this.$root.$emit("showSocialModal");
		},
	},
	computed: {
		...mapGetters([
			"user",
			"currentRecipe",
			"editMode",
			"currentRecipeEdited",
			"isAuthenticated",
		]),
		isRecipeView() {
			return this.$route.name == "Recipe";
		},
		isSharedRecipeView() {
			return this.$route.name == "SharedRecipe";
		},
		isNewRecipeView() {
			return this.isRecipeView && this.$route.path == "/recipes/new";
		},
	},
};
</script>

<style>
#navbar,
#navbar-anonymous {
	display: flex;
	height: 60px;
	background: white;
	box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 10px;
	position: fixed;
	bottom: 0;
	width: 100%;
	align-items: center;
}

.navbar-tab {
	font-size: 10px;
	width: 50%;
	display: grid;
	justify-content: center;
	color: black;
}

.navbar-tab-icon {
	font-size: 25px;
	margin: auto;
}

.navbar-tab:hover {
	cursor: pointer;
	color: #f0ac2d;
}

.navbar-tab > span {
	margin-top: 5px;
}

#navbar-login-btn {
	margin: auto;
	height: 32px;
	width: 150px;
	border-radius: 12px;
	background: #f0ac2d;
	color: #ffffff;
	font-size: 16px;
	font-family: Roboto;
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: center;
}

#navbar-login-btn:hover {
	cursor: pointer;
}
</style>
