<template>
	<div class="current-recipe-tab current-recipe-direction-container">
		<div v-if="!editMode">
			<div v-for="(direction, index) in currentRecipe.directions" :key="index">
				<div class="current-recipe-direction">
					<span class="direction-index">{{ index + 1 }}. {{ direction }}</span>
				</div>
			</div>
		</div>
		<div v-else>
			<div v-for="(direction, index) in recipeEditedDirections" :key="index">
				<div class="current-recipe-direction">
					<p class="current-recipe-direction-input-group">
						<span>{{ index + 1 }}. </span>
						<textarea
							class="current-recipe-direction-textarea"
							v-model="recipeEditedDirections[index]"
						/><button
							class="remove-direction-btn"
							@click="removeDirection(index)"
						>
							<font-awesome-icon icon="trash" />
						</button>
					</p>
				</div>
			</div>
			<p class="add-direction-btn" @click="addDirection">Ajouter une étape</p>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	data() {
		return {};
	},
	methods: {
		addDirection() {
			this.$store.commit("setCurrentRecipeEditedDirections", [
				...this.recipeEditedDirections,
				"",
			]);
		},
		removeDirection(index) {
			let directions = JSON.parse(JSON.stringify(this.recipeEditedDirections));
			directions.splice(index, 1);
			this.$store.commit("setCurrentRecipeEditedDirections", directions);
		},
	},
	computed: {
		...mapGetters(["currentRecipe", "editMode", "currentRecipeEdited"]),
		recipeEditedDirections: {
			get() {
				return this.$store.state.recipe.currentRecipeEdited.directions;
			},
			set(value) {
				this.$store.commit("setCurrentRecipeEditedDirections", value);
			},
		},
	},
};
</script>

<style>
.add-direction-btn {
	margin: auto;
	height: 32px;
	width: fit-content;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 12px;
	background: #f0ac2d;
	color: #ffffff;
	font-size: 14px;
	font-family: Montserrat;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
