<template>
	<div>
		<div id="menu-description">
			<p class="menu-title">Ajoute une nouvelle recette</p>
		</div>

		<div class="action-container">
			<div id="url-card" class="action-card" @click="$router.push('/url')">
				<div class="action-card-left">
					<img src="../assets/search.svg" />
				</div>
				<div class="action-card-right">
					<p class="action-card-title">Importe une recette</p>
					<p class="action-card-subtitle">
						depuis les sites de cuisine les plus populaires
					</p>
				</div>
			</div>

			<div id="custom-card" class="action-card" @click="createRecipe">
				<div class="action-card-left">
					<img src="../assets/feather.svg" />
				</div>
				<div class="action-card-right">
					<p class="action-card-title">Rédige une recette</p>
					<p class="action-card-subtitle">
						manuellement qui n’existe nulle part ailleurs
					</p>
				</div>
			</div>

			<div id="ocr-card" class="action-card" @click="$router.push('/ocr')">
				<div class="action-card-left">
					<img src="../assets/camera.svg" />
				</div>
				<div class="action-card-right">
					<p class="action-card-title">Scanne une recette</p>
					<p class="action-card-subtitle">
						depuis n’importe quelle page de ton livre de cuisine favori
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
	created() {
		// Shallow copy so we can cancel edits
		if (this.currentRecipe) {
			this.updateCurrentRecipeEdited(
				JSON.parse(JSON.stringify(this.currentRecipe))
			);
		}
	},
	methods: {
		...mapActions(["updateCurrentRecipe", "enterEditMode"]),
		createRecipe() {
			this.updateCurrentRecipe({
				directions: [
					"Pour commencer, cliquez sur le bouton Éditer.",
					"Choisissez un nom et modifiez le nom de votre nouvelle recette.",
					"Ajoutez des ingrédients ainsi que des étapes de préparation comme bon vous semble.",
					"Si quelque chose n'est pas à votre goût, vous pouvez toujours l'enlever en cliquant sur la corbeille.",
					"Lorsque c'est prêt, cliquez sur Sauvegarder.",
				],
				ingredients: ["1 nom de recette"],
				name: "Je crée ma recette",
				servings: 1,
			});

			this.$router.push("/recipes/new");
			this.enterEditMode();
		},
	},
};
</script>

<style>
#menu-description {
	padding: 30px 24px;
}

.menu-title {
	color: #2c3e50;
	font-size: 24px;
	font-family: Montserrat;
	font-weight: 700;
	line-height: 28px;
	letter-spacing: -0.02em;
}

.action-container {
	padding-left: 24px;
	padding-right: 24px;
}

.action-card {
	height: 120px;
	width: 100%;
	border-radius: 12px;
	background: #000000;
	display: flex;
	margin-bottom: 15px;
}

.action-card:hover {
	cursor: pointer;
}

#url-card {
	background: #f0ac2d;
}

#custom-card {
	background: #02aab0;
}

#ocr-card {
	background: #e74c3c;
}

.action-card-left {
	padding-top: 23px;
	padding-left: 25px;
	padding-right: 15px;
	color: #ffffff;
}

.action-card-left > img {
	width: 24px;
	height: 24px;
}

.action-card-right {
	padding-top: 26px;
	color: #ffffff;
}

.action-card-title {
	margin-top: 0px;
	font-weight: 300;
	font-size: 16px;
	line-height: 16px;
	color: #ffffff;
	margin-bottom: 4px;
}

.action-card-subtitle {
	width: 240px;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	opacity: 0.6;
	margin-top: 0px;
}
</style>
