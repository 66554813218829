const state = {
    user: null
}

const getters = {
    user: (state) => state.user,
    isAuthenticated: (state) => {
        return state.user !== null
    },
    username: (state) => {
        if (state.user) {
            return state.user.displayName.split(' ')[0]
        }
    },
    isMobile: () => {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    }
}

const mutations = {
    setUser: (state, user) => { 
        state.user = user 
    },
}

const actions = {
    updateUser({ commit }, user) {
        commit('setUser', user)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}