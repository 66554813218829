<template>
	<div class="website-link" v-if="Object.keys(site).length > 0">
		<p>
			Retrouvez la recette originale sur
			<a :href="url" target="_blank">{{ site.name }}</a
			>.
		</p>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	data() {
		return {
			websites: [
				{ domain: "www.marmiton.org", name: "marmiton" },
				{ domain: "www.750g.com", name: "750g" },
				{ domain: "www.cuisineaz.com", name: "Cuisine AZ" },
				{
					domain: "cuisine.journaldesfemmes.fr",
					name: "le Journal des Femmes",
				},
			],
		};
	},
	computed: {
		...mapGetters(["currentRecipe"]),
		url() {
			if (this.currentRecipe) {
				return this.currentRecipe.url;
			} else {
				return "";
			}
		},
		site() {
			if (this.url) {
				let site = {};
				const tokens = this.url.split("/");
				for (let i = 0; i < this.websites.length; i++) {
					if (tokens.includes(this.websites[i].domain)) {
						site = this.websites[i];
					}
				}
				return site;
			} else {
				return {};
			}
		},
	},
};
</script>

<style>
.website-link {
	padding: 5px;
	height: 120px;
	text-align: center;
	font-weight: 600;
	font-family: Montserrat;
}

.website-link > p > a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
</style>
