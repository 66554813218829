const state = {
	currentRecipe: null,
	editMode: false,
	currentRecipeEdited: null,
	isSavingRecipe: false,
};

const getters = {
	currentRecipe: (state) => state.currentRecipe,
	editMode: (state) => state.editMode,
	currentRecipeEdited: (state) => state.currentRecipeEdited,
	isSavingRecipe: (state) => state.isSavingRecipe,
};

const mutations = {
	setIsSavingRecipe: (state, isSavingRecipe) => {
		state.isSavingRecipe = isSavingRecipe;
	},
	setCurrentRecipe: (state, recipe) => {
		state.currentRecipe = recipe;
	},
	setCurrentRecipeIngredients: (state, ingredients) => {
		state.currentRecipe.ingredients = ingredients;
	},
	setCurrentRecipeDirections: (state, directions) => {
		state.currentRecipe.directions = directions;
	},
	setCurrentRecipeCover: (state, cover) => {
		state.currentRecipe.cover = cover;
	},
	setCurrentRecipeEdited: (state, recipe) => {
		state.currentRecipeEdited = recipe;
	},
	setCurrentRecipeEditedName: (state, name) => {
		state.currentRecipeEdited.name = name;
	},
	setCurrentRecipeEditedServings: (state, servings) => {
		state.currentRecipeEdited.servings = servings;
	},
	setCurrentRecipeEditedIngredients: (state, ingredients) => {
		state.currentRecipeEdited.ingredients = ingredients;
	},
	setCurrentRecipeEditedDirections: (state, directions) => {
		state.currentRecipeEdited.directions = directions;
	},
	setCurrentRecipeEditedImage: (state, image) => {
		state.currentRecipeEdited.image = image;
	},
	setEditMode: (state, value) => {
		state.editMode = value;
	},
};

const actions = {
	updateIsSavingRecipe({ commit }, isSavingRecipe) {
		commit("setIsSavingRecipe", isSavingRecipe);
	},
	updateCurrentRecipe({ commit }, recipe) {
		commit("setCurrentRecipe", recipe);
	},
	updateCurrentRecipeIngredients({ commit }, ingredients) {
		commit("setCurrentRecipeIngredients", ingredients);
	},
	updateCurrentRecipeDirections({ commit }, directions) {
		commit("setCurrentRecipeDirections", directions);
	},
	updateCurrentRecipeCover({ commit }, cover) {
		commit("setCurrentRecipeCover", cover);
	},
	updateCurrentRecipeEdited({ commit }, recipe) {
		commit("setCurrentRecipeEdited", recipe);
	},
	updateCurrentRecipeEditedIngredients({ commit }, ingredients) {
		commit("setCurrentRecipeEditedIngredients", ingredients);
	},
	updateCurrentRecipeEditedDirections({ commit }, directions) {
		commit("setCurrentRecipeEditedDirections", directions);
	},
	updateCurrentRecipeEditedImage({ commit }, image) {
		commit("setCurrentRecipeEditedImage", image);
	},
	enterEditMode({ state, commit }) {
		commit("setEditMode", true);
		commit(
			"setCurrentRecipeEdited",
			JSON.parse(JSON.stringify(state.currentRecipe))
		);
	},
	cancelEdits({ state, commit }) {
		commit(
			"setCurrentRecipeEdited",
			JSON.parse(JSON.stringify(state.currentRecipe))
		);
	},
	saveEdits({ state, commit }) {
		commit(
			"setCurrentRecipe",
			JSON.parse(JSON.stringify(state.currentRecipeEdited))
		);
	},
	quitEditMode({ commit, dispatch }, withoutSaving) {
		if (withoutSaving) {
			dispatch("cancelEdits");
		} else {
			dispatch("saveEdits");
		}
		commit("setEditMode", false);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
