var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"current-recipe-cover"},[(_vm.isLoading)?_c('div',{attrs:{"current-recipe-cover-loader":""}},[_c('Loader',{attrs:{"message":_vm.loadingMessage}})],1):_c('div',[(_vm.currentRecipeCover && (!_vm.editMode || _vm.src == null))?_c('img',{staticClass:"current-recipe-cover-image",attrs:{"src":_vm.currentRecipeCover}}):_vm._e(),(_vm.editMode)?_c('cropper',{staticClass:"cropper",attrs:{"src":_vm.src,"minWidth":200,"stencil-props":{
				aspectRatio: 3 / 2,
				handlers: {
					eastNorth: true,
					north: false,
					westNorth: true,
					west: false,
					westSouth: true,
					south: false,
					eastSouth: true,
					east: false,
				},
			}},on:{"change":_vm.change}}):_vm._e(),_c('br'),(_vm.editMode)?_c('label',{staticClass:"label-file",attrs:{"for":"image-selector"}},[_vm._v(" "+_vm._s(_vm.currentRecipeCover ? "Changer l'image" : "Ajouter une image")+" ")]):_vm._e(),(_vm.editMode)?_c('input',{staticClass:"file-selector",attrs:{"id":"image-selector","name":"image","type":"file"},on:{"change":_vm.onFileChange}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }