const state = {
	recipeList: null,
};

const getters = {
	recipeList: (state) => state.recipeList,
	recipeListLength: (state) => {
		if (state.recipeList) {
			return state.recipeList.length;
		} else {
			return 0;
		}
	},
};

const mutations = {
	setRecipeList: (state, recipeList) => {
		state.recipeList = recipeList;
	},
	insertRecipe: (state, recipe) => {
		state.recipeList.unshift(recipe);
	},
	removeRecipe: (state, recipe) => {
		const index = state.recipeList.findIndex((r) => r.id == recipe.id);
		state.recipeList.splice(index, 1);
	},
	updateRecipe: (state, recipe) => {
		const index = state.recipeList.findIndex((r) => r.id == recipe.id);
		state.recipeList[index] = recipe;
	},
};

const actions = {
	async fetchUserRecipes({ commit, rootState }) {
		const token = await rootState.login.user.getIdToken();
		const url = "https://pingo-backend-sdauzsikga-ew.a.run.app/user/recipes/";
		const response = await fetch(url, {
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${token}`,
			},
		});
		const data = await response.json();
		commit("setRecipeList", data);
	},
	async saveRecipeDB({ rootState }, { recipe }) {
		const token = await rootState.login.user.getIdToken();
		//const endpoint = process.env.VUE_APP_ENDPOINT
		const url = "https://pingo-backend-sdauzsikga-ew.a.run.app/user/recipes/";
		// const url = "http://localhost:8000/user/recipes";
		const response = await fetch(url, {
			method: "POST",
			headers: {
				authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(recipe),
		});
		return await response.json();
	},
	async deleteExistingRecipeDB({ rootState }, recipe) {
		// Delete existing recipe
		const token = await rootState.login.user.getIdToken();
		const url = "https://pingo-backend-sdauzsikga-ew.a.run.app/user/recipes/";
		//const url = "http://localhost:8000/user/recipes/";
		const response = await fetch(url, {
			method: "DELETE",
			headers: {
				authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(recipe),
		});
		return response;
	},
	insertNewRecipeStore({ commit }, recipe) {
		commit("insertRecipe", recipe);
	},
	removeExistingRecipeStore({ commit }, recipe) {
		commit("removeRecipe", recipe);
	},
	updateExistingRecipeStore({ commit }, recipe) {
		commit("updateRecipe", recipe);
	},
	resetRecipeList({ commit }) {
		commit("setRecipeList", null);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
