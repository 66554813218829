<template>
	<div class="current-recipe-tab calorie-container">
		<div v-if="calories != null">
			<highcharts v-if="hasEnoughInfo" :options="chartOptions"></highcharts>
			<div style="font-size: 12px; text-align: left">
				<p>
					<span>calories</span>
					<span style="float: right">{{ calories }} kcal</span>
				</p>
				<p>
					<span>protéines</span>
					<span style="float: right">{{ proteins }}g</span>
				</p>
				<p>
					<span>glucides</span>
					<span style="float: right">{{ carbohydrates }}g</span>
				</p>
				<p>
					<span>lipides</span>
					<span style="float: right">{{ lipids }}g</span>
				</p>
			</div>

			<p style="margin-top: 50px">
				🚧 <strong>Aide au développement</strong> 🚧
			</p>
			<p style="font-size: 12px">
				Ci-dessous la liste des ingrédients ayant été identifiés pour le calcul
				des valeurs nutritionnelles. Si certains ingrédients de la recette n'y
				figurent pas, merci de les reporter !
			</p>
			<div style="font-size: 12px; text-align: left">
				<p v-for="(ingredient, index) in nutrition" :key="index">
					<span v-if="ingredient.from">{{ ingredient.from }}</span>
					<span style="float: right" v-if="ingredient.from"
						>{{ ingredient.calories }} kcal</span
					>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { Chart } from "highcharts-vue";

export default {
	data() {
		return {
			nutrition: null,
			chartOptions: {
				chart: {
					type: "pie",
				},
				title: {
					text: null,
				},
				series: [
					{
						type: "pie",
						name: "Quantité (g)",
						innerSize: "30%",
						data: [],
					},
				],
			},
		};
	},
	components: {
		highcharts: Chart,
	},
	computed: {
		...mapGetters(["currentRecipe"]),
		calories() {
			if (!this.nutrition) return 0;

			return this.nutrition.reduce(function (total, ingredient) {
				return total + ingredient.calories;
			}, 0);
		},
		proteins() {
			if (!this.nutrition) return 0;

			return this.nutrition.reduce(function (total, ingredient) {
				return total + ingredient.proteines;
			}, 0);
		},
		lipids() {
			if (!this.nutrition) return 0;

			return this.nutrition.reduce(function (total, ingredient) {
				return total + ingredient.lipides;
			}, 0);
		},
		carbohydrates() {
			if (!this.nutrition) return 0;

			return this.nutrition.reduce(function (total, ingredient) {
				return total + ingredient.glucides;
			}, 0);
		},
		hasEnoughInfo() {
			return this.carbohydrates + this.lipids + this.proteins > 0;
		},
	},
	methods: {
		async getCalories() {
			// const response = await fetch('http://localhost:8000/calories', {
			const response = await fetch(
				"https://pingo-backend-sdauzsikga-ew.a.run.app/calories/",
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ ingredients: this.currentRecipe.ingredients }),
				}
			);
			this.nutrition = await response.json();
		},
	},
	watch: {
		nutrition() {
			this.chartOptions.series[0].data = [
				{ name: "Protéines", y: this.proteins, color: "#23cfcf" },
				{ name: "Glucides", y: this.carbohydrates, color: "#ff3f69" },
				{ name: "Lipides", y: this.lipids, color: "#ffcd56" },
			];
		},
	},
	mounted() {
		this.getCalories();
	},
};
</script>

<style></style>
