<template>
  <div class="current-recipe-tab current-recipe-ingredient-container">
    <div v-if="!editMode">
      <div
        v-for="(ingredient, index) in currentRecipe.ingredients"
        :key="index"
      >
        <div class="current-recipe-ingredient">
          <p class="current-recipe-ingredient-input-group">
            <span>-</span>
            <span>{{ ingredient }}</span>
          </p>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-for="(ingredient, index) in recipeEditedIngredients" :key="index">
        <div class="current-recipe-ingredient">
          <p class="current-recipe-ingredient-input-group">
            <span>-</span>
            <input
              class="current-recipe-ingredient-input"
              v-model="recipeEditedIngredients[index]"
            /><button
              class="remove-ingredient-btn"
              @click="removeIngredient(index)"
            >
              <font-awesome-icon icon="trash" />
            </button>
          </p>
        </div>
      </div>
      <p class="add-ingredient-btn" @click="addIngredient">
        Ajouter un ingrédient
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  methods: {
    addIngredient() {
      this.$store.commit("setCurrentRecipeEditedIngredients", [
        ...this.recipeEditedIngredients,
        "",
      ]);
    },
    removeIngredient(index) {
      let ingredients = JSON.parse(
        JSON.stringify(this.recipeEditedIngredients)
      );
      ingredients.splice(index, 1);
      this.$store.commit("setCurrentRecipeEditedIngredients", ingredients);
    },
  },
  computed: {
    ...mapGetters(["currentRecipe", "editMode", "currentRecipeEdited"]),
    recipeEditedIngredients: {
      get() {
        return this.$store.state.recipe.currentRecipeEdited.ingredients;
      },
      set(value) {
        this.$store.commit("setCurrentRecipeEditedIngredients", value);
      },
    },
  },
};
</script>

<style>
.current-recipe-ingredient {
  font-weight: 500;
  margin-bottom: 10px;
}

.current-recipe-ingredient-input-group {
  display: grid;
  grid-template-columns: 20px 3fr 40px;
  align-items: center;
  grid-row-gap: 50px;
}

.current-recipe-ingredient-input {
  font-weight: 500;
  font-size: 14px;
  font-family: Inter;
  border-radius: 2px;
  border: none;
}

.add-ingredient-btn {
  margin: auto;
  height: 32px;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 12px;
  background: #f0ac2d;
  color: #ffffff;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>