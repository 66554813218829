<template>
  <div class="current-recipe-servings">
    <span v-if="!editMode"
      >Pour
      <span>{{ currentRecipe.servings }}</span>
      {{ servingsText(currentRecipe.servings) }}
    </span>

    <span v-else
      >Pour
      <input v-model="recipeEditedServings" />
      {{ servingsText(recipeEditedServings) }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  methods: {
    servingsText(n) {
      return n > 1 ? "personnes" : "personne";
    },
  },
  computed: {
    ...mapGetters(["currentRecipe", "editMode", "currentRecipeEdited"]),
    recipeEditedServings: {
      get() {
        return this.$store.state.recipe.currentRecipeEdited.servings;
      },
      set(value) {
        this.$store.commit("setCurrentRecipeEditedServings", value);
      },
    },
  },
};
</script>

<style>
.current-recipe-servings,
.current-recipe-servings > span > input {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.current-recipe-servings > span > input {
  width: 30px;
  text-align: center;
}
</style>