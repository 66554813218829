import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuex from "vuex";
import vmodal from "vue-js-modal";
import VueClipboard from "vue-clipboard2";
import VueSocialSharing from "vue-social-sharing";
import HighchartsVue from "highcharts-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faFileAlt,
	faBookOpen,
	faEdit,
	faShare,
	faSave,
	faTrash,
	faTimes,
	faArrowRight,
	faLink,
	faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./registerServiceWorker";

library.add(
	faBookOpen,
	faFileAlt,
	faEdit,
	faShare,
	faSave,
	faTrash,
	faTimes,
	faArrowRight,
	faLink,
	faPowerOff
);

Vue.use(Vuex);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(BootstrapVue);
Vue.use(vmodal, { dialog: true });
Vue.use(VueSocialSharing);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(HighchartsVue);
Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
