<template>
	<div>
		<div id="ocr-recipe-section">
			<p class="ocr-recipe-section-title">Scanne une recette</p>
			<div class="ocr-recipe-section-description">
				<span
					>Prenez en photo une page de votre livre de recettes favori. Faites
					bien attention à ce que les ingrédients et les étapes de préparation y
					figurent puis valider.</span
				>
			</div>
			<div v-if="isLoading" class="ocr-text-loader">
				<p class="ocr-text-loader-message">
					{{ loadingMessage }}
				</p>
				<b-progress
					:value="progressValue"
					:max="100"
					show-progress
					animated
				></b-progress>
			</div>
			<div v-else>
				<div v-if="src" class="img-container">
					<img ref="image" :src="src" crossorigin height="500px" />
					<div
						style="display: flex; align-items: center; justify-content: center"
					>
						<button class="ocr-btn-validate" @click="scan">Valider</button>
						<button class="ocr-btn-cancel" @click="resetImage">Annuler</button>
					</div>
				</div>
				<div v-else>
					<label for="image-selector-ocr" class="label-file"
						>Je prends une photo</label
					>
					<input
						id="image-selector-ocr"
						class="file-selector"
						name="image"
						type="file"
						@change="onFileChange"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
	data() {
		return {
			src: null,
			ingredients: null,
			directions: null,
			isLoading: false,
			loadingMessage: "Analyse de l'image",
			progressValue: 0,
		};
	},
	methods: {
		...mapActions(["updateCurrentRecipe"]),
		onFileChange(e) {
			var vm = this;
			new Promise(function (resolve) {
				vm.src = "";
				resolve();
			}).then(function () {
				if (e) {
					var files = e.target.files || e.dataTransfer.files;
					if (!files.length) return;
					vm.createImage(files[0]);
				}
			});
		},
		createImage(file) {
			let reader = new FileReader();
			reader.onload = (e) => {
				let img = new Image();
				img.onload = () => {
					let canvas = document.createElement("canvas");
					let ctx = canvas.getContext("2d");
					const aspectRatio = img.width / img.height;
					canvas.width = 800 * aspectRatio;
					canvas.height = 800;
					ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
					this.src = canvas.toDataURL();
				};
				img.src = e.target.result;
			};
			reader.readAsDataURL(file);
		},
		async scan() {
			this.isLoading = true;
			this.startTimer();
			const data = { dataURI: this.src };
			//const endpoint = "http://localhost:8000";
			const endpoint = "https://pingo-ocr-sdauzsikga-ew.a.run.app";
			const url = `${endpoint}/ocr/v2`;
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});
			const recipe = await response.json();
			this.ingredients = recipe.ingredients;
			this.directions = recipe.directions;
			this.isLoading = false;
			this.createRecipe();
		},
		createRecipe() {
			this.updateCurrentRecipe({
				directions: this.directions
					? this.directions
					: ["Ajouter une étape de préparation"],
				ingredients: this.ingredients
					? this.ingredients
					: ["Ajouter un ingrédient"],
				name: "Je crée ma recette",
				servings: 1,
				url: "https://pingo.one/",
			});
			this.$router.push("/recipes/new");
		},
		resetImage() {
			this.src = null;
		},
		startTimer() {
			this.progressValue = 0;
			setInterval(() => {
				this.progressValue = (++this.progressValue % 360) + 1; // SET { 1-360 }
			}, 1000);
		},
	},
	mounted() {
		this.onFileChange(this.$route.params.fileChangeEvent);
	},
};
</script>

<style scoped>
#ocr-recipe-section {
	padding: 30px 24px;
}

.ocr-recipe-section-description {
	background: rgb(236, 241, 252);
	border-radius: 16px;
	padding: 25px;
	margin-bottom: 20px;
}

.ocr-recipe-section-title {
	color: #2c3e50;
	font-size: 24px;
	font-family: Montserrat;
	font-weight: 700;
	line-height: 28px;
	letter-spacing: -0.02em;
}

.img-container {
	width: 100%;
	text-align: center;
}
.img-container > img {
	width: 100%;
	max-width: 600px;
	object-fit: contain;
	margin: auto;
}
.ocr-btn-validate {
	height: 32px;
	border: 0;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 20px;
	padding-right: 20px;
	font-family: Roboto;
	font-weight: 700;
	font-size: 15px;
	color: white;
	background: #5588eb;
	border-radius: 20px;
	border: 1px solid #5588eb;
	margin-top: 25px;
	margin-bottom: 55px;
	margin-right: 5px;
}
.ocr-btn-validate:hover {
	color: #5588eb;
	background: white;
	border: 1px solid #5588eb;
}
.ocr-btn-cancel {
	height: 32px;
	border: 0;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 20px;
	padding-right: 20px;
	font-family: Roboto;
	font-weight: 700;
	font-size: 15px;
	color: white;
	background: #e74c3c;
	border-radius: 20px;
	border: 1px solid #e74c3c;
	margin-top: 25px;
	margin-bottom: 55px;
	margin-left: 5px;
}
.ocr-btn-cancel:hover {
	color: #e74c3c;
	background: white;
	border: 1px solid #e74c3c;
}

.ocr-text-loader {
	width: 90%;
	max-width: 700px;
	height: 300px;
	padding: 30px;
}

.ocr-text-loader-message {
	font-size: 20px;
	font-family: Montserrat;
	font-weight: 700;
	margin-bottom: 30px;
	text-align: center;
	color: #2c3e50;
}
</style>
