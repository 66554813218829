import Vue from "vue";
import Vuex from "vuex";
import login from "./modules/login";
import cookbook from "./modules/cookbook";
import recipe from "./modules/recipe";
import search from "./modules/search";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		login,
		cookbook,
		recipe,
		search,
	},
});
