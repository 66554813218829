<template>
	<div id="url-recipe-section">
		<p class="url-recipe-section-title">Importe une recette</p>
		<div class="url-recipe-section-description">
			<span
				>Saisis l’URL d’une recette provenant de l’un des sites ci-dessous
				:</span
			>
			<ul>
				<li v-for="(site, index) in sites" :key="index">{{ site }}</li>
			</ul>
		</div>
		<input
			id="url-input"
			v-model="url"
			placeholder="https://www.750g.com/..."
		/>
		<div id="scraping-btn" @click="importRecipeFromURL">
			<span>J'importe la recette</span>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
	data() {
		return {
			url: null,
			scrapingLoading: false,
			sites: [
				"Marmiton",
				"750g",
				"Cuisine AZ",
				"Hervé Cuisine",
				"Le Journal des Femmes",
				"Madame Figaro",
				"Cuisine Actuelle",
			],
		};
	},
	methods: {
		...mapActions(["updateCurrentRecipe"]),
		async importRecipeFromURL() {
			if (this.url) {
				try {
					this.scrapingLoading = true;
					const endpoint =
						"https://pingo-backend-sdauzsikga-ew.a.run.app/scraper/";
					const response = await fetch(endpoint, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							url: this.url,
						}),
					});
					if (response.status == 200) {
						const recipe = await response.json();
						this.updateCurrentRecipe(recipe);
						this.scrapingLoading = false;
						if (this.$route.path !== "/recipes/new") {
							this.$router.push("/recipes/new");
						}
					} else {
						this.scrapingLoading = false;
						alert(
							`Nous n'avons pas réussi à récupérer la recette. Vérifiez que la recette provient d'un des sites suivants :
                            marmiton, 750g, Cuisine AZ, le Journal des Femmes.`
						);
					}
				} catch (err) {
					console.log(err);
				}
			}
		},
	},
};
</script>

<style>
#url-recipe-section {
	padding: 30px 24px;
}

.url-recipe-section-description {
	background: rgb(236, 241, 252);
	border-radius: 16px;
	padding: 25px;
}

.url-recipe-section-title {
	color: #2c3e50;
	font-size: 24px;
	font-family: Montserrat;
	font-weight: 700;
	line-height: 28px;
	letter-spacing: -0.02em;
}

#url-input {
	width: 100%;
	border-radius: 20px;
	height: 32px;
	border-color: rgba(44, 62, 80, 0.75);
	border-width: 1px;
	padding-left: 10px;
	margin-top: 20px;
	margin-bottom: 25px;
}

#scraping-btn {
	margin: auto;
	height: 32px;
	width: fit-content;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 12px;
	background: #f0ac2d;
	color: #ffffff;
	font-size: 16px;
	font-family: Montserrat;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
}

#scraping-btn:hover {
	cursor: pointer;
}
</style>
