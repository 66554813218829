<template>
	<div class="text-loader">
		<p class="text-loader-message">
			{{ message }}
		</p>
		<div class="loading-spinner">
			<PulseLoader :color="loaderColor" />
		</div>
	</div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
	components: {
		PulseLoader,
	},
	props: {
		message: String,
	},
	data() {
		return {
			loaderColor: "#5588eb",
		};
	},
};
</script>

<style scoped>
.text-loader {
	width: 90%;
	max-width: 700px;
	height: 300px;
	padding: 50px;
}

.text-loader-message {
	font-size: 20px;
	font-family: Montserrat;
	font-weight: 700;
	margin-bottom: 50px;
	text-align: center;
	color: #2c3e50;
}

.loading-spinner {
	text-align: center;
}
</style>
