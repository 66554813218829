import Vue from "vue";
import VueRouter from "vue-router";
import Cookbook from "../views/Cookbook.vue";
import Login from "../views/Login.vue";
import Recipe from "../views/Recipe.vue";
import URLRecipe from "../views/menu/URLRecipe.vue";
import OCR from "../views/menu/OCR.vue";
import SharedRecipe from "../views/SharedRecipe.vue";
import Menu from "../views/Menu.vue";
import firebase from "firebase/app";
import "firebase/auth";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
	{
		path: "/recipes/shared/:id",
		name: "SharedRecipe",
		component: SharedRecipe,
	},
	{
		path: "/cookbook",
		name: "Cookbook",
		component: Cookbook,
		alias: "/",
		meta: { requiresAuth: true },
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
	},
	{
		path: "/recipes/:id",
		name: "Recipe",
		component: Recipe,
	},
	{
		path: "/recipes/new",
		component: Recipe,
	},
	{
		path: "/menu",
		name: "Menu",
		component: Menu,
	},
	{
		path: "/url",
		name: "URLRecipe",
		component: URLRecipe,
	},
	{
		path: "/ocr",
		name: "OCR",
		component: OCR,
	},
];

let router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

firebase.getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
			unsubscribe();
			resolve(user);
		}, reject);
	});
};

router.beforeEach(async (to, from, next) => {
	const currentUser = await firebase.getCurrentUser();
	store.dispatch("updateUser", currentUser);
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

	if (requiresAuth && !currentUser) {
		next({
			path: "/login",
			params: { nextUrl: to.fullPath },
		});
	} else if (!requiresAuth && currentUser) {
		//next({ name: 'home' })
		next();
	} else {
		next();
	}
});

export default router;
