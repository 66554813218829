<template>
	<div id="cookbook">
		<p class="cookbook-title">Mes recettes ({{ recipeListLength }})</p>
		<Loader v-if="isLoading" :message="loadingMessage" />
		<div v-else>
			<transition appear name="list">
				<div v-if="recipeListLength > 0" class="cookbook-card-container">
					<CookbookCard
						v-for="recipe in recipeList"
						:key="recipe.id"
						:recipe="recipe"
					/>
				</div>
				<div v-else class="cookbook-onboarding-message">
					<p>
						Vous n'avez pas encore de recette enregistrée, commencer par ajouter
						une recette en cliquant sur
					</p>
					<p class="new-recipe-shortcut" @click="goToMenu">
						<font-awesome-icon class="new-recipe-icon" icon="file-alt" />
						<span class="new-recipe-link">Nouvelle recette</span>
					</p>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import CookbookCard from "../components/cookbook/CookbookCard.vue";
import Loader from "@/components/loader/Loader.vue";

export default {
	components: {
		CookbookCard,
		Loader,
	},
	data() {
		return {
			loadingMessage: "Récupération de vos recettes en cours",
		};
	},
	methods: {
		goToMenu() {
			this.$router.push("/menu");
		},
	},
	computed: {
		...mapGetters(["recipeList", "recipeListLength"]),
		isLoading() {
			return !this.$store.state.cookbook.recipeList;
		},
	},
	mounted() {
		if (!this.$store.state.cookbook.recipeList) {
			this.$store.dispatch("fetchUserRecipes");
		}
	},
};
</script>

<style scoped>
#cookbook {
	padding: 30px 20px 100px 20px;
}

.cookbook-title {
	color: #2c3e50;
	font-size: 24px;
	font-family: Montserrat;
	font-weight: 700;
	line-height: 28px;
	letter-spacing: -0.02em;
}

.cookbook-card-container {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
}

.cookbook-onboarding-message {
	background: rgb(236, 241, 252);
	border-radius: 16px;
	padding: 25px;
}

.new-recipe-shortcut:hover {
	cursor: pointer;
}

.new-recipe-icon {
	font-size: 22px;
	margin-right: 10px;
}

.new-recipe-link {
	font-weight: 700;
	color: #2c3e50;
	font-size: 18px;
}

.list-enter-active,
.list-leave-active {
	transition: all 0.5s;
}

.list-enter,
.list-leave-to {
	opacity: 0;
	transform: translateY(30px);
}
</style>
