<template>
	<div class="cookbook-card" @click="displayCurrentRecipe(recipe)">
		<p>
			<span>{{ shortenName(recipe.name, 100) }}</span>
		</p>
		<img v-if="recipeImage" :src="composeImage(recipe)" />
		<img v-else src="../../assets/food.svg" />
	</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
	props: {
		recipe: Object,
	},
	computed: {
		recipeImage() {
			return this.composeImage(this.recipe);
		},
	},
	methods: {
		...mapActions(["updateCurrentRecipe"]),
		composeImage(recipe) {
			if (recipe.image) {
				const image = encodeURIComponent(recipe.image);
				return `https://storage.cloud.google.com/pingo-images/${image}`;
			}
		},
		shortenName(name, maxLength) {
			/* Ensure that names do not exceed maxLength characters. */
			return name.length > maxLength
				? `${name.substring(0, maxLength - 3)}...`
				: name;
		},
		displayCurrentRecipe(recipe) {
			this.updateCurrentRecipe(recipe);
			this.$router.push(`/recipes/${recipe.id}`);
		},
	},
};
</script>

<style>
.cookbook-card:hover {
	cursor: pointer;
}

.cookbook-card > p {
	height: 50px;
	width: 100%;
	margin: 0px;
	text-align: left;
	font-size: 16px;
	font-family: Montserrat;
	font-weight: 600;
	hyphens: auto;
	color: #3c3c3c;
	padding: 15px;
}

.cookbook-card {
	display: flex;
	height: 100px;
	border-radius: 16px;
	margin-bottom: 5px;
	border: 1px solid rgb(236, 241, 252);
}

.cookbook-card:hover {
	cursor: pointer;
}

.cookbook-card > p {
	margin-top: 0px;
}

.cookbook-card > img {
	height: 100%;
	width: 98px;
	object-fit: cover;
	border-radius: 0px 16px 16px 0px;
	background: rgb(236, 241, 252);
}
</style>
