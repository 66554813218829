<template>
	<div class="header">
		<div class="logo-btn">
			<img src="../assets/pingo_circle.svg" />
			<h2>Pingo</h2>
		</div>

		<div v-if="isAuthenticated" id="sidebar-toggle-btn">
			<img src="../assets/sidebar.png" v-b-toggle.sidebar-menu />
		</div>

		<b-sidebar id="sidebar-menu" right backdrop shadow no-header>
			<div class="sidebar-container">
				<h2 class="sidebar-title">
					{{ title }}
				</h2>
				<div class="sidebar-item">
					<font-awesome-icon class="sidebar-icon" icon="power-off" />
					<p @click="logout">Se déconnecter</p>
				</div>
			</div>
		</b-sidebar>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";

export default {
	computed: {
		...mapGetters(["isAuthenticated", "user", "username"]),
		title() {
			if (this.username) {
				return `Bonjour ${this.username} !`;
			} else {
				return "Bonjour !";
			}
		},
	},
	methods: {
		...mapActions(["updateUser", "resetRecipeList", "updateCurrentRecipe"]),
		logout() {
			firebase
				.auth()
				.signOut()
				.then(() => {
					this.updateUser(null);
					this.resetRecipeList();
					this.updateCurrentRecipe(null);
					if (this.$route.path != "/login") this.$router.push("/login");
				});
		},
	},
};
</script>

<style>
.header {
	height: 84px;
	display: flex;
	padding: 0px 20px;
	align-items: center;
	box-shadow: rgb(0 0 0 / 5%) 0px 3px 10px;
	background-image: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 0)
	);
}

.header h2 {
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	margin-left: 10px;
}

.logo-btn {
	display: flex;
	align-items: center;
}

.logo-btn > h2 {
	font-family: Pacifico;
	color: #5588eb;
}

.logo-btn > img {
	height: 50px;
}

.sidebar-container {
	padding: 20px;
}

.sidebar-container > h2 {
	font-family: Montserrat;
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 20px;
	margin-left: 0px;
}

#sidebar-toggle-btn > img {
	height: 50px;
}

#sidebar-toggle-btn {
	margin-right: 5px;
	margin-left: auto;
	order: 2;
	border-radius: 100px;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: center;
}

.sidebar-toggle-btn:hover {
	cursor: pointer;
}

#sidebar-menu {
	background: #fafafa !important;
}

.sidebar-item {
	display: flex;
	align-items: top;
	font-size: 16px;
}

.sidebar-item:hover {
	cursor: pointer;
}

.sidebar-icon {
	font-size: 25px;
	margin-right: 10px;
}
</style>
