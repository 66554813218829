<template>
	<div class="shared-recipe">
		<div v-if="currentRecipe">
			<RecipeName />
			<RecipeServings />
			<RecipeCover />

			<vue-tabs>
				<v-tab title="Ingrédients"><RecipeIngredients /></v-tab>
				<v-tab title="Préparation"><RecipeDirections /></v-tab>
				<v-tab title="Nutrition"><Nutrition /></v-tab>
			</vue-tabs>
		</div>
		<div v-else>
			<Loader message="Chargement de la recette" />
		</div>
	</div>
</template>

<script>
import { VueTabs, VTab } from "vue-nav-tabs";
import Nutrition from "@/components/Nutrition.vue";
import RecipeName from "@/components/recipe/RecipeName.vue";
import RecipeCover from "@/components/recipe/RecipeCover.vue";
import RecipeServings from "@/components/recipe/RecipeServings.vue";
import RecipeIngredients from "@/components/recipe/RecipeIngredients.vue";
import RecipeDirections from "@/components/recipe/RecipeDirections.vue";
import Loader from "@/components/loader/Loader.vue";
import { mapActions, mapGetters } from "vuex";

export default {
	components: {
		VueTabs,
		VTab,
		Nutrition,
		RecipeName,
		RecipeCover,
		RecipeServings,
		RecipeIngredients,
		RecipeDirections,
		Loader,
	},
	methods: {
		...mapActions(["updateCurrentRecipe"]),
		async getSharedRecipe(id) {
			//const endpoint = process.env.VUE_APP_ENDPOINT
			const endpoint = "https://pingo-backend-sdauzsikga-ew.a.run.app";
			const url = `${endpoint}/user/recipes/public/${id}`;
			const response = await fetch(url);
			if (response.status == 200) {
				this.sharedRecipe = await response.json();
			}
			this.updateCurrentRecipe(this.sharedRecipe);
		},
	},
	computed: {
		...mapGetters(["currentRecipe", "user", "isAuthenticated"]),
		recipeId() {
			const encodedId = this.$route.params.id;
			return encodedId.replace(/\D/g, "");
		},
	},
	mounted() {
		this.getSharedRecipe(this.recipeId);
	},
};
</script>

<style>
.shared-recipe {
	padding: 30px 24px;
}
</style>
