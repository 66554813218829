<template>
	<div id="app">
		<Header />
		<div v-if="updateExists" @click="refreshApp" class="update-snackbar">
			Une nouvelle version est disponible. Cliquez ici pour mettre à jour.
		</div>
		<div style="max-width: 500px; margin: auto">
			<router-view />
		</div>

		<Navbar />
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Header";
import Navbar from "@/components/Navbar";

export default {
	data() {
		return {
			refreshing: false,
			registration: null,
			updateExists: false,
		};
	},
	components: {
		Header,
		Navbar,
	},
	computed: {
		...mapGetters(["isAuthenticated"]),
	},
	created() {
		document.addEventListener("swUpdated", this.updateAvailable, {
			once: true,
		});
		navigator.serviceWorker.addEventListener("controllerchange", () => {
			// We'll also need to add 'refreshing' to our data originally set to false.
			if (this.refreshing) return;
			this.refreshing = true;
			// Here the actual reload of the page occurs
			window.location.reload();
		});
	},
	methods: {
		updateAvailable(event) {
			this.registration = event.detail;
			this.updateExists = true;
		},
		refreshApp() {
			this.updateExists = false;
			// Make sure we only send a 'skip waiting' message if the SW is waiting
			if (!this.registration || !this.registration.waiting) return;
			// Send message to SW to skip the waiting and activate the new SW
			this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
		},
	},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("//fonts.googleapis.com/css?family=Roboto:400,100,300,100italic,300italic,400italic,500italic,500,700,700italic,900,900italic");
@import url("//fonts.googleapis.com/css?family=Roboto+Mono:400,100,300,100italic,300italic,400italic,500italic,500,700,700italic,900,900italic");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

#app {
	font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: auto;
}

body {
	margin: 0px;
}

.update-snackbar {
	min-height: 40px;
	background: #e74c3c;
	padding: 5px;
	color: white;
	text-align: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.update-snackbar:hover {
	cursor: pointer;
}
</style>
