<template>
	<div class="current-recipe-cover">
		<div current-recipe-cover-loader v-if="isLoading">
			<Loader :message="loadingMessage" />
		</div>
		<div v-else>
			<img
				class="current-recipe-cover-image"
				v-if="currentRecipeCover && (!editMode || src == null)"
				:src="currentRecipeCover"
			/>
			<cropper
				v-if="editMode"
				class="cropper"
				:src="src"
				:minWidth="200"
				:stencil-props="{
					aspectRatio: 3 / 2,
					handlers: {
						eastNorth: true,
						north: false,
						westNorth: true,
						west: false,
						westSouth: true,
						south: false,
						eastSouth: true,
						east: false,
					},
				}"
				@change="change"
			/>
			<br />
			<label for="image-selector" class="label-file" v-if="editMode">
				{{ currentRecipeCover ? "Changer l'image" : "Ajouter une image" }}
			</label>
			<input
				id="image-selector"
				class="file-selector"
				v-if="editMode"
				name="image"
				type="file"
				@change="onFileChange"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import Loader from "@/components/loader/LoaderLocal.vue";

export default {
	components: {
		Cropper,
		Loader,
	},
	data() {
		return {
			isLoading: false,
			loadingMessage: "Chargement de l'image",
			src: null,
		};
	},
	props: {
		editable: Boolean,
	},
	computed: {
		...mapGetters(["user", "currentRecipe", "editMode"]),
		currentRecipeCover() {
			if (this.currentRecipe.image) {
				const image = encodeURIComponent(this.currentRecipe.image);
				return `https://storage.cloud.google.com/pingo-images/${image}`;
			} else {
				return null;
			}
		},
	},
	methods: {
		...mapActions(["updateCurrentRecipeEditedImage"]),
		change({ canvas }) {
			this.updateCurrentRecipeEditedImage(canvas.toDataURL("image/png"));
		},
		onFileChange(e) {
			let vm = this;
			vm.isLoading = true;
			new Promise(function (resolve) {
				vm.src = "";
				resolve();
			}).then(function () {
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;
				vm.createImage(files[0]);
			});
		},
		createImage(file) {
			let reader = new FileReader();
			reader.onload = (e) => {
				let img = new Image();
				img.onload = () => {
					let canvas = document.createElement("canvas");
					let ctx = canvas.getContext("2d");
					const aspectRatio = img.width / img.height;
					canvas.width = 800 * aspectRatio;
					canvas.height = 800;
					ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
					this.src = canvas.toDataURL();
					this.isLoading = false;
				};
				img.src = e.target.result;
			};
			reader.readAsDataURL(file);
		},
	},
};
</script>

<style>
.current-recipe-cover {
	margin: 10px 0px;
}

.current-recipe-cover-loader {
	height: 500px;
	width: 100%;
}

.current-recipe-cover-image {
	max-width: 100%;
	max-height: 500px;
	object-fit: cover;
	border-radius: 8px;
}

.vue-simple-handler {
	width: 20px;
	height: 20px;
	background: #f0ac2d;
}

.label-file {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 50px;
	height: 32px;
	max-width: 200px;
	width: fit-content;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 12px;
	background: #5588eb;
	color: #ffffff;
	font-size: 14px;
	font-family: Montserrat;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
}

.label-file:hover {
	cursor: pointer;
}

.file-selector {
	display: none;
}
</style>
