export const encode = (word) => {
	let randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
	let result = "";
	for (let i = 0; i < word.length; i++) {
		result +=
			randomChars.charAt(Math.floor(Math.random() * randomChars.length)) +
			word[i];
	}
	return result;
};
