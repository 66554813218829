<template>
	<transition name="fade" appear>
		<div class="current-recipe">
			<div v-if="isSavingRecipe">
				<Loader message="Enregistrement en cours" />
			</div>
			<div v-else>
				<RecipeName />
				<RecipeServings />
				<RecipeCover />

				<vue-tabs activeTextColor="#5588eb">
					<v-tab title="Ingrédients"><RecipeIngredients /></v-tab>
					<v-tab title="Préparation"><RecipeDirections /></v-tab>
					<v-tab title="Nutrition"><Nutrition /></v-tab>
				</vue-tabs>
				<RecipeWebLink />
			</div>
			<modal name="socialModal" width="300px" height="120px">
				<div style="text-align: center; margin-top: 10px">
					<p style="font-size: 20px"><strong>Partager via</strong></p>
					<hr />
					<ShareNetwork
						network="email"
						:url="shareableLink"
						:title="message"
						:description="currentRecipe.name"
					>
						<img class="share-network-icon" src="../assets/gmail.png" />
					</ShareNetwork>
					<ShareNetwork
						network="whatsapp"
						:url="shareableLink"
						:title="message"
						:description="currentRecipe.name"
					>
						<img class="share-network-icon" src="../assets/whatsapp.png" />
					</ShareNetwork>
					<a
						v-clipboard:copy="shareableLink"
						class="share-network-link"
						@click="getShareableLink"
					>
						<font-awesome-icon icon="link" style="font-size: 15px" />
					</a>
				</div>
			</modal>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from "vuex";
import { VueTabs, VTab } from "vue-nav-tabs";
import Nutrition from "@/components/Nutrition.vue";
import RecipeName from "@/components/recipe/RecipeName.vue";
import RecipeCover from "@/components/recipe/RecipeCover.vue";
import RecipeServings from "@/components/recipe/RecipeServings.vue";
import RecipeIngredients from "@/components/recipe/RecipeIngredients.vue";
import RecipeDirections from "@/components/recipe/RecipeDirections.vue";
import RecipeWebLink from "@/components/recipe/RecipeWebLink.vue";
import Loader from "@/components/loader/Loader.vue";
import "vue-nav-tabs/themes/vue-tabs.css";
import { encode } from "@/utils/helpers";

export default {
	components: {
		Loader,
		VueTabs,
		VTab,
		Nutrition,
		RecipeName,
		RecipeCover,
		RecipeServings,
		RecipeIngredients,
		RecipeDirections,
		RecipeWebLink,
	},
	data() {
		return {
			networks: [
				{
					network: "email",
					name: "Email",
					src: "./assets/gmail.png",
					color: "#333333",
				},
				{
					network: "whatsapp",
					name: "Whatsapp",
					src: "./assets/whatsapp.png",
					color: "#25d366",
				},
			],
		};
	},
	methods: {
		showSocialModal() {
			this.$modal.show("socialModal");
		},
		getShareableLink() {
			this.$bvToast.toast(this.shareableLink, {
				title: "Lien de partage copié dans le presse-papier.",
				variant: "success",
				autoHideDelay: 5000,
			});
		},
	},
	mounted() {
		this.$root.$on("showSocialModal", () => {
			this.showSocialModal();
		});
	},
	computed: {
		...mapGetters([
			"username",
			"editMode",
			"currentRecipe",
			"currentRecipeEdited",
			"isSavingRecipe",
		]),
		message() {
			return `${this.username} vous partage sa recette !`;
		},
		currentRecipeCover() {
			if (this.currentRecipe.cover && this.currentRecipe.cover.length > 0) {
				return this.currentRecipe.cover[0].image;
			} else {
				return null;
			}
		},
		shareableLink() {
			if (this.currentRecipe && this.currentRecipe.id) {
				const encodedId = encode(this.currentRecipe.id.toString());
				return `https://pingo.one/recipes/shared/${encodedId}`;
			} else {
				return "https://pingo.one/";
			}
		},
	},
};
</script>

<style>
.current-recipe {
	padding: 30px 24px;
}

.title {
	font-size: 14px !important;
	font-family: Montserrat;
	font-weight: 700;
	display: unset !important;
}

.current-recipe-tab {
	padding: 25px 10px;
	margin-bottom: 100px;
	font-size: 14px;
}

.current-recipe-direction-input-group {
	display: grid;
	grid-template-columns: 20px 3fr 40px;
	align-items: top;
	grid-row-gap: 50px;
}

.current-recipe-direction-textarea {
	font-weight: 500;
	font-size: 14px;
	font-family: Inter;
	padding-bottom: 20px;
}

.current-recipe-direction {
	margin-bottom: 25px;
}

.remove-ingredient-btn,
.remove-direction-btn {
	background: white;
	border: 0;
	width: 25px;
	height: 25px;
	justify-self: end;
}

.fade-enter-active {
	transition: opacity 1s;
}

.fade-enter {
	opacity: 0;
}

.share-network-icon {
	height: 35px;
	margin-left: 10px;
	margin-right: 10px;
}

.share-network-link {
	color: black;
	border-radius: 50%;
	background: rgb(236, 241, 252);
	padding-top: 8px;
	padding-bottom: 8px;
	padding-right: 11px;
	padding-left: 11px;
	margin: 5px;
}
</style>
