var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"action-container"},[_c('div',{staticClass:"action-card",attrs:{"id":"url-card"},on:{"click":function($event){return _vm.$router.push('/url')}}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"action-card",attrs:{"id":"custom-card"},on:{"click":_vm.createRecipe}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"action-card",attrs:{"id":"ocr-card"},on:{"click":function($event){return _vm.$router.push('/ocr')}}},[_vm._m(5),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"menu-description"}},[_c('p',{staticClass:"menu-title"},[_vm._v("Ajoute une nouvelle recette")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action-card-left"},[_c('img',{attrs:{"src":require("../assets/search.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action-card-right"},[_c('p',{staticClass:"action-card-title"},[_vm._v("Importe une recette")]),_c('p',{staticClass:"action-card-subtitle"},[_vm._v(" depuis les sites de cuisine les plus populaires ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action-card-left"},[_c('img',{attrs:{"src":require("../assets/feather.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action-card-right"},[_c('p',{staticClass:"action-card-title"},[_vm._v("Rédige une recette")]),_c('p',{staticClass:"action-card-subtitle"},[_vm._v(" manuellement qui n’existe nulle part ailleurs ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action-card-left"},[_c('img',{attrs:{"src":require("../assets/camera.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action-card-right"},[_c('p',{staticClass:"action-card-title"},[_vm._v("Scanne une recette")]),_c('p',{staticClass:"action-card-subtitle"},[_vm._v(" depuis n’importe quelle page de ton livre de cuisine favori ")])])
}]

export { render, staticRenderFns }